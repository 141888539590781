<script>
// Extensiones
import View from '@/components/View'

// Mixins
import LoadSections from '@/mixins/load-sections'

export default {
  name: 'Home',

  metaInfo: { title: 'Home'},

  extends: View,

  mixins: [
    LoadSections([
      'home-header',
      'home-services',
      'home-equipment'
    ]),
  ],

  props: {
    id: {
      type: String,
      default: 'home',
    },
  },
}
</script>